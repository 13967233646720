/**
 * Created by Tomasz Kotlarek (ZICHER) on 28.12.2017.
 */

define('application/map',["application/options"], function (appOptions) {
  return function ($document) {
    $document.on("app.preparegooglemaps", function () {
      var map;
      //var $map = $(this);
      var $map = $(".js-map");

      var googlemapsloadedInitialize = function () {
        var options = {
          center: new google.maps.LatLng(appOptions.map.center.latitude, appOptions.map.center.longitude),
          styles: appOptions.map.styles
        };

        map = new google.maps.Map($map[0], $.extend({}, appOptions.map.apiOptions, options));

        new google.maps.Marker({
          position: new google.maps.LatLng(appOptions.map.center.latitude, appOptions.map.center.longitude),
          map: map,
          icon: appOptions.map.marker.icon
        });
      };

      var googleMapsLoadedFully = false;
      var $window = $(window);

      $window.on("scroll", function () {
        if (false === googleMapsLoadedFully) {
          var toScroll = $document.height() - $window.height() - 1500;

          if ($(this).scrollTop() > toScroll) {
            googlemapsloadedInitialize();
            googleMapsLoadedFully = true;
          }
        }
      });
    });

    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-map").each(function () {


        if (typeof googlemapsloaded !== "undefined" && googlemapsloaded === true) {
          //googlemapsloadedInitialize();
          $document.trigger("app.preparegooglemaps");
        } else {
          $document.on("app.googlemapsloaded", function () {
            //googlemapsloadedInitialize();
            $document.trigger("app.preparegooglemaps");
          });
        }
      });

      data.dom.find(".js-map-image").on("click", function () {
        $(this).addClass("active");
        $document.find(".js-map-hint").addClass("active");
        //map.setZoom(14);
      });
    });

    return this;
  }
});
